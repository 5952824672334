<template>
  <div class="back-top"  ref="BackTop" id="BackTop">
    <ul>
      <li v-for="item of exchangeList" :key="item.title" @click="itemClick(item.title)">
        <img :src="item.imgUrl" alt="" class="custom-color">
        <span>{{ item.title }}</span>
      </li>
      <transition name="el-zoom-in-center">
        <div v-show="wxShow" class="transition-box wx-box">
          <div class="show-box">
            <img src="@/assets/images/qrcode.png" alt="">
            <div class="right">
              <div class="wx">
                <img src="@/assets/images/new-ui/ic_wchat@2x.png" alt="">
                扫码咨询
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="el-zoom-in-center">
        <div v-show="gzhShow" class="transition-box">
          <div class="show-box">
            <img src="@/assets/images/new-ui/pic_ewm@2x.png" alt="">
            <div class="right">
              <div class="title">
                <p>每天关注五分钟</p>
                <p>招标投标不用愁</p>
              </div>
              <div class="wx">
                <img src="@/assets/images/new-ui/ic_wchat@2x.png" alt="">
                扫码关注
              </div>
            </div>
          </div>
        </div>
      </transition>
    </ul>
  </div>
</template>

<script>
export default {
  mounted() {
    if (window.innerHeight < 1000) {
      this.$refs.BackTop.style.transform = "scale(0.8)"; 
      this.$refs.BackTop.style.right = "20px"; 
      this.$refs.BackTop.style.bottom = "-40px"; 
    } else {
      this.$refs.BackTop.style.transform = "scale(1)"; 
      this.$refs.BackTop.style.right = "40px"; 
      this.$refs.BackTop.style.bottom = "0px"; 
      // this.$refs.BackTop.style.top = "0"; 
    }
    window.addEventListener('resize', () => {
      if (window.innerHeight < 1000) {
        this.$refs.BackTop.style.transform = "scale(0.8)"; 
        this.$refs.BackTop.style.right = "20px"; 
        this.$refs.BackTop.style.bottom = "-40px"; 
      // this.$refs.BackTop.style.top = "70px"; 
      } else {
        this.$refs.BackTop.style.transform = "scale(1)"; 
        this.$refs.BackTop.style.right = "40px"; 
        this.$refs.BackTop.style.bottom = "0px"; 
      // this.$refs.BackTop.style.top = "0"; 
      }
    })
  },
  destroyed() {
    window.removeEventListener("resize");
  },
  beforeDestroy() {
    console.log("BackTop组件销毁了");
    // 清除window上的东西，如定时器、滚动事件
    window.onscroll = null;
    clearInterval(this.timeId);
  },
  data() {
    return {
      gzhShow: false,
      wxShow: false,
      timeId: null,
      exchangeList: [
        { title: "在线客服", 
          imgUrl: require("../../assets/images/new-ui/fd_ic_zxkf.svg") 
        },
        { title: "问卷", 
          imgUrl: require("../../assets/images/new-ui/fd_ic_wq.svg") 
        },
        { title: "公众号", 
          imgUrl: require("../../assets/images/new-ui/fd_ic_gzh.svg") 
        },
        { title: "返回顶部", 
          imgUrl: require("../../assets/images/new-ui/fd_ic_fhdb.svg") 
        }
      ],
    };
  },
  methods: {
    itemClick(title) {
      if (title === '公众号') {
        this.gzhShow = !this.gzhShow
        this.wxShow = false
      }
      if (title === '问卷') {
        window.open('https://www.wjx.cn/vm/mBMmVPx.aspx#')
      }
      if (title === '在线客服') {
        this.wxShow = !this.wxShow
        this.gzhShow = false
      }
      if (title === '返回顶部') {
        this.backTop()
      }
    },
    backTop() {
      clearInterval(this.timeId); //防止多次点击而启动多个定时器
      this.timeId = setInterval(() => {
        if (document.documentElement.scrollTop > 0) {
          document.documentElement.scrollTop -= 100;
        } else {
          clearInterval(this.timeId);
        }
      }, 10);
    }
  }
};
</script>

<style lang="less" scoped>
.back-top {
  position: fixed;
  right: 40px;
  bottom: 0;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 51;
  ul {
    position: relative;
  }
  li {
    width: 95px;
    height: 95px;
    background: #fff;
    box-shadow: 0px 0px 16px 1px rgba(0,0,0,0.05);
    border-radius: 8px 8px 8px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;
    .custom-color {
      position: relative;
    }
    &:hover {
      .custom-color {
        transform: translateX(-80px);
        filter: drop-shadow(#F36E31 80px 0);     
      }
      span {
        color: #F36E31;
      }
    }
    span {
      color: #999;
      font-size: 14px;
      margin-top: 8px;
    }
  }
  .show-box {
    width: 324px;
    height: 192px;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 1px rgba(0,0,0,0.05);
    border-radius: 12px 12px 12px 12px;
  }
  .wx-box {
    top: -50px !important;
    .right {
      justify-content: center !important;
    }
  }
  .transition-box {
    position: absolute;
    left: -360px;
    bottom: 70px;
    .show-box {
      padding: 24px;
      display: flex;
      justify-content: space-between;
      >img {
        height: 144px;
        margin-right: 16px;
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-weight: bold;
        font-size: 16px;
        .title {
          color: #333;
          line-height: 26px;
        }
        .wx {
          color: #47C333;
          >img {
            height: 44px;
          }
        }
      }
    }
  }
}

</style>